import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div>
    
    <footer id="footer" className="footer mt-5">
  <div className="footer-content position-relative">
    <div className="container ">
      <div className="row d-flex justify-content-center">
        <div className="col-md-4 ">
          <div className="footer-info">
            <h3>Menber Construction</h3>
            <p>
            Addis Abeba, Bole Sub-city, Zurga Building 4th floor<br />
              <strong>Phone:</strong> +251 900003351<br />
              <strong>Email:</strong> menberconstruction@gmail.com<br />
            </p>
            <div className="social-links d-flex mt-3">
              <Link to={'https://t.me/Visaconsu'} className="d-flex align-items-center justify-content-center"><i className="bi bi-telegram" /></Link>
              <Link to={'https://www.facebook.com/profile.php?id=100054575825125'} className="d-flex align-items-center justify-content-center"><i className="bi bi-facebook" /></Link>
              <Link className="d-flex align-items-center justify-content-center"><i className="bi bi-instagram" /></Link>
              <Link className="d-flex align-items-center justify-content-center"><i className="bi bi-linkedin" /></Link>
            </div>
          </div>
        </div>
        <div className="col-md-4  col-6 footer-links">
          <h4>Useful Links</h4>
          <ul>
            <li><Link to={'/'}>Home</Link></li>
            <li><Link to={'/about'}>About us</Link></li>
            <li><Link to={'/vacancy'}>Vacancy</Link></li>
            <li><Link to={'/blog'}>Blogs</Link></li>
            <li><Link to={'/completedprojects'}>Completed Projects</Link></li>
            <li><Link to={'/ongoingprojects'}>Ongoing Projects</Link></li>
          </ul>
        </div>
        <div className="col-md-4  col-6 footer-links">
          <h4>Our Services</h4>
          <ul>
            <li><Link >Building construction</Link></li>
            <li><Link >Finishing</Link></li>
            <li><Link >Architectural Design</Link></li>
            <li><Link >Construction Machinery Rentals</Link></li>
            <li><Link >Water & Irrigation Works Construction</Link></li>
            <li><Link >Excavation, Earth Works & Landscaping</Link></li>
           
          </ul>
        </div>
       
      </div>
    </div>
  </div>
  <div className="footer-legal text-center position-relative">
    <div className="container">
      <div className="copyright">
        © Copyright <strong><span>menber Construction</span></strong>. All Rights Reserved
      </div>
    </div>
  </div>
</footer>

    
    </div>
  )
}

export default Footer