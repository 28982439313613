import React, { useEffect, useState } from 'react'
import Header from './Header'

import HB2 from '../images/yy.jpg'
import HB1 from '../images/uu.jpg'


import about from '../images/about.jpg'
import db from '../images/nb3.jpeg'


import HomBlog from './HomBlog'
import Services from './Services'
import HomeProjects from './HomeProjects'
import Test from './Test'
import axios from 'axios'
import renovation from '../images/sprayer.png'

import shovel from '../images/construction.png'

import bulldozer from '../images/svg/003-bulldozer.svg'
import plan from '../images/svg/004-house-plan.svg'
import fence from '../images/office.png'
import wheelbarrow from '../images/s2.jpeg'
import { Link } from 'react-router-dom'

function Home() {

  const [data, setdata] = useState([]);
  useEffect(() => {
      axios.get("https://backend.menberconstructions.com.et/testimonfetch")
      .then(res=>{
  if (res.data.status==="success") {
      setdata(res.data.Result.sort((a, b) => b.id - a.id))
      
  }
          
      })
      .catch(err=>console.log(err))
     
  }, []);

  return (
   <div>
   <Header/>
   
  
    <div className="wrapper">


    <div className=' bg-dark'>
    <p className='text-dark mb-5'>naol</p>
    </div>
    <div id="carousel" className="carousel slide " data-ride="carousel">
    <ol className="carousel-indicators">
        <li data-target="#carousel" data-slide-to="0" className="active"></li>
        <li data-target="#carousel" data-slide-to="1"></li>
        <li data-target="#carousel" data-slide-to="2"></li>
    </ol>
    <div className="carousel-inner">
        <div className="carousel-item active">
<img className='wid' src={db} alt=''/>
            <div className="carousel-caption">
                
                <h1 className="animated fadeInLeft text-success">MENBER CONSTRUCTION</h1>
                <h2 className="animated fadeInRight">We Build Tomorrow</h2>
               
            </div>
        </div>

        <div className="carousel-item">
        <img className='wid' src={HB2} alt=''/>
            <div className="carousel-caption">
                <p className="animated fadeInRight">We Build Tomorrow
                </p>
                <h1 className="animated fadeInLeft">Trusted and Quality services </h1>

            </div>
        </div>

        <div className="carousel-item">
        <img className='wid' src={HB1} alt=''/>

          
        </div>
    </div>

    <a className="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
    </a>
    <a className="carousel-control-next" href="#carousel" role="button" data-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
    </a>
</div>
    



<section id="features" className="features section-bg">
<div className=" section-header">
        <h2>Our Profile</h2>
      
      </div>
<div className="container" data-aos="fade-up">

<ul className="nav nav-tabs row  g-2 d-flex">

<li className="nav-item col-3">
  <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-1">
    <h4>History</h4>
  </a>
</li>

<li className="nav-item col-3">
  <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
    <h4>Values</h4>
  </a>
  </li>
<li className="nav-item col-3">
  <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3">
    <h4>Vision</h4>
  </a>
</li>

<li className="nav-item col-3">
  <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-4">
    <h4>Mission</h4>
  </a>
</li>
</ul>
  <div className="tab-content">

    <div class="tab-pane active show" id="tab-1">
    <div className="row">
    <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
      <h3>BACKGROUND HISTORY</h3>
      <p className="fst-italic text-justify">
      First Established in 2012 as a Grade V Building contractor (BC5) and Grade III Water works, 
      under the legal name of Menber construction PLC, registered in Ministry of Construction works 
      Regulatory Authority has now grown to a Grade III Building Contractor (BC3) and Grade III 
      Water Works on the course of planning one of the few trusted contractors that can manage to 
      handle and execute complex projects of higher caliber. In the company’s six-year history, 
      Menber construction continues to show good achievements which can be traced in its truck 
      records. It has grown its capacity in capital and manpower to match the ever-growing demand of 
      the construction industry through understanding clients core values and spirations that is the key 
      to its success.</p>
     
    </div>
    <div className="col-lg-6 order-1 order-lg-2 text-center">
      <img src={about} alt="" className="img-fluid"/>
    </div>
  </div>
    </div>

    <div class="tab-pane" id="tab-2">
    <div className="row">
<div className="col-lg-6 order-1 order-lg-1 text-center">
      <img src={about} alt="" className="img-fluid"/>
    </div>

    <div className="col-lg-6 order-2 order-lg-2 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
    
      <p className="fst-italic text-justify">
      The company strives to be an example in the construction industry by supplying quality product 
      with reasonable cost in timely manner. Menber Construction PLC has made all the necessary 
      preparation to make sure that it achieves its objectives & deliver with excellence to our clients 
      and partners. 
      
      </p>
      <ul>
        <li><i className="bi bi-check2-all"></i><b>QUALITY:</b>we are committed to delivering projects on 
        time, within budget, and exceeding quality standards.</li>
        <li><i className="bi bi-check2-all"></i><b>SAFETY:</b>we prioritize the safety of our employees, 
        subcontractors, and the public.</li>
        <li><i className="bi bi-check2-all"></i><b>CLIENT FOCUS:</b>we build strong relationships with our 
        clients and are dedicated to understanding and exceeding their needs. </li>
        <li><i className="bi bi-check2-all"></i><b>TEAMWORK:</b>: we value collaboration, communication, and mutual respect 
        with our team.</li>
        <li><i className="bi bi-check2-all"></i><b>INNOVATION:</b>we embrace new technologies and methods to continuously 
        improve our processes and services.  </li>
        <li><i className="bi bi-check2-all"></i><b>SUSTAINABILITY:</b>we are committed to environmentally responsible 
        construction practice and minimizing our impact.</li>
      </ul>
    </div>
   
  </div>
      
    </div>

    <div class="tab-pane" id="tab-3">
      <div className="row">
        <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
          <h3>Vision</h3>
          
          <p className="fst-italic text-justify">
          Building a Better Ethiopia: Delivering exceptional, safe and sustainable 
          construction projects across Ethiopia, for living, working, and travel. We 
          achieve this through collaboration, innovation, and a commitment to quality 
          craftsmanship, exceeding our clients’ expectations and improving the quality of life. 
          </p>
          
        </div>
        <div className="col-lg-6 order-1 order-lg-2 text-center">
          <img src={about} alt="" className="img-fluid"/>
        </div>
      </div>
    </div>

    <div class="tab-pane" id="tab-4">
    <div className="row">
    <div className="col-lg-6 order-2 order-lg-2 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
 <h3>Mission</h3>
      
      <p className="fst-italic text-justify">
      Building a Better Ethiopia: Delivering exceptional, safe and sustainable 
construction projects across Ethiopia, for living, working, and travel. We 
achieve this through collaboration, innovation, and a commitment to quality 
craftsmanship, exceeding our clients’ expectations and improving the quality of 
life. 
      </p>
    </div>
    <div className="col-lg-6 order-1 order-lg-1 text-center">
      <img src={about} alt="" className="img-fluid"/>
    </div>
  </div>
    </div>

  </div>

</div>
</section>




<div>

<div class="site-section pb-0" style={{backgroundColor: '#F4F4F4',paddingTop:'50px'}}>

<div className="section-header">
<h2 >Services</h2>

</div>

<div className="container">
  <div className="row align-items-stretch">
    <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
      <div className="service-1 h-100">
      <Link to={'/servicedetail'}>
        <div className="svg">
        <img src={fence} alt='' className=""/>
       
        </div>

        <h3><span>Building construction</span></h3>
       
</Link>
      </div>
    </div>

    <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
    <div className="service-1 h-100">
    <Link to={'/servicedetail'}>
      <div className="svg2 ">

        <img src={wheelbarrow} alt="" className=""/>
      </div>

      <h3><span>Construction Machinery Rentals</span></h3>
      </Link>

    </div>
  </div>


    <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
      <div className="service-1 h-100">
      <Link to={'/servicedetail'}>
        <div className="svg">
        <img src={renovation} alt="" className=""/>
        </div>

        <h3><span>Water & Irrigation Works Construction</span></h3>
        </Link>

      </div>
    </div>


    <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
      <div className="service-1 h-100">
      <Link to={'/servicedetail'}>
        <div className="svg">
          <img src={shovel} alt="" className=""/>
        </div>

        <h3><span>Finishing</span></h3>
        </Link>

      </div>
    </div>
    <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
      <div className="service-1 h-100">
      <Link to={'/servicedetail'}>
        <div className="svg">
        <img src={plan} alt="" className=""/>
      </div>
        <h3><span>Architectural Design</span></h3>

        </Link>
      </div>
    </div>


    <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
      <div className="service-1 h-100">

   <Link to={'/servicedetail'}>
      <div className="svg">
      <img src={bulldozer} alt="" className=""/>
    </div>

        <h3><span>Excavation, Earth Works & Landscaping</span></h3>
       
        </Link>
      </div>

    </div>

  
   

  </div>
</div>
</div><section id="services" className="services section-bg">
<div className="container" data-aos="fade-up">



 
</div>
</section>



</div>




<HomBlog/>



<HomeProjects/>




{data.length>0 &&   <Test testimonials={data} />}




</div>




</div>

    
  )
}

export default Home