import React, { useEffect, useState } from 'react'

import axios from 'axios';

function HomeProjects() {
  const [hover, sethover] = useState("All");

  const [data, setdata] = useState([]);


  useEffect(() => {
      axios.get("https://backend.menberconstructions.com.et/portfoliofetch")
      .then(res=>{
  if (res.data.status==="success") {
    console.log(res.data.Result);
      setdata(res.data.Result.sort((a, b) => b.id - a.id))
      
  }
          
      })
      .catch(err=>console.log(err))
     
  }, []);


  const handlehover=(status)=>{
      sethover(status)
      
      
      
          }



  return (
    <div>
    
    {data.length>0 &&  <section id="projects" className="projects">
      <div className="container" data-aos="fade-up">

        <div className="section-header">
          <h2>Projects</h2>
         
        </div>

       

          <ul className="portfolio-flters" >
            <li  onClick={()=>handlehover('All')} className={`${hover==="All" && "filter-active"}`} >All</li>
            <li onClick={()=>handlehover('Ongoing')} className={`${hover==="Ongoing" && "filter-active"}`}>Ongoing</li>
            <li  onClick={()=>handlehover('completed')} className={`${hover==="completed" && "filter-active"}`}>Completed</li>
          </ul>

<div className="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay="200">
{data.filter((porti) => porti.type === hover).slice(0, 3).map((ports,i)=>{
  return <div className="col-lg-4 col-md-6 portfolio-item filter-design" key={i}>
              <div className="portfolio-content h-100">
              <img src={'https://backend.menberconstructions.com.et/images/' + ports.image} class="img-fluid" alt=""/>
                <div className="portfolio-info">
                  <h4><i className="bi bi-geo-alt-fill"></i> {ports.location}</h4>
                  <p>{ports.name}</p>
                 
                </div>
              </div>
          

          </div>
})}
{hover==="All"&&data.slice(0, 3).map((ports,i)=>{
  return <div className="col-lg-4 col-md-6 portfolio-item filter-design" key={i}>
  <div className="portfolio-content h-100">
  <img src={'https://backend.menberconstructions.com.et/images/' + ports.image} class="img-fluid" alt=""/>
    <div className="portfolio-info">
      <h4><i className="bi bi-geo-alt-fill"></i> {ports.location}</h4>
      <p>{ports.name}</p>
      
      
    </div>
  </div>


</div>})}

        </div>

     
      </div>
    </section>}
    
    
    
    
    
    </div>
  )
}

export default HomeProjects