import React from 'react';
import { Card } from 'react-bootstrap';

const Let = ({ Image, Name,Position, Message }) => {
  return (
    <Card style={{ width: '18rem', flex: '0 0 auto', marginRight: '15px' }} >
    <Card.Body className='dyy text-center shadow'>
    <div>
      <img src={'https://backend.menberconstructions.com.et/images/' +Image}  className='iiii' style={{ width: '150px', height: '150px', marginBottom: '15px' }} alt=''/>
      <Card.Title><b>{Name}</b></Card.Title>
      <Card.Subtitle ><span className='text-primary'>{Position}</span></Card.Subtitle>
      <p className='px-1 text-justify'><i class="bi bi-quote h3 text-warning"></i><i>{Message}</i><i class="bi bi-quote text-warning"></i></p>
     
      </div>
    </Card.Body>
   
  </Card>
  );
};

export default Let;