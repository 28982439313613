import React from 'react'
import renovation from '../images/sprayer.png'

import shovel from '../images/construction.png'

import bulldozer from '../images/svg/003-bulldozer.svg'
import plan from '../images/svg/004-house-plan.svg'
import fence from '../images/office.png'
import wheelbarrow from '../images/s2.jpeg'
import Header from './Header'
import { Link } from 'react-router-dom'
function Services() {
  return (
    <div>
    <Header/>
    <div class="breadcrumbs d-flex align-items-center lolo" >
    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
  
      <h2>Services Detail</h2>
      <ol>
        <li><Link to={'/'}>Home</Link></li>
        <li className="text-warning">Services</li>
      </ol>
  
    </div>
  </div>




      <div class="site-section pb-0" style={{backgroundColor: '#F4F4F4',paddingTop:'50px'}}>
      
    <div className="section-header">
    <h2 >Services</h2>
   
  </div>

      <div className="container">
        <div className="row align-items-stretch">
          <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
            <div className="service-2 h-100">
              <div className="svg">
              <img src={fence} alt='' className=""/>
             
              </div>

              <h3><span>Building construction</span></h3>
              <p className='text-justify'>
             Our passion and expertise intersect in the realm of building construction. We blend dedication to top-notch craftsmanship with a keen eye for detail, turning architectural plans into tangible structures. Our goal is to produce buildings that are not only structurally robust and visually appealing but also customized to your needs, surpassing your anticipations.</p>

            </div>
          </div>

          <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
          <div className="service-2 h-100">
            <div className="svg2 ">
              <img src={wheelbarrow} alt="" className=""/>
            </div>

            <h3><span>Construction Machinery Rentals</span></h3>
            <p className='text-justify'>You have access to a wide selection of construction machinery rentals right at your disposal. Our extensive fleet includes excavators, bulldozers, and cranes, all meticulously maintained and readily accessible to fulfill your project needs. With these tools at your disposal, you're equipped for efficient and smooth operations, empowering your construction endeavors.</p>

          </div>
        </div>


          <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
            <div className="service-2 h-100">
              <div className="svg">
              <img src={renovation} alt="" className=""/>
              </div>

              <h3><span>Water & Irrigation Works Construction</span></h3>
              <p className='text-justify'>Specializing in water and irrigation works construction, our team ensures meticulous execution and expertise in every aspect of your project. From laying the groundwork to shaping the terrain, we handle each step with precision, guaranteeing optimal conditions for your irrigation systems. Our dedication extends beyond functionality to enhancing the natural landscape, ensuring that your water and irrigation projects not only meet but exceed expectations, leaving a lasting impact on the surrounding environment.</p>

            </div>
          </div>


          <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
            <div className="service-2 h-100">
              <div className="svg">
                <img src={shovel} alt="" className=""/>
              </div>

              <h3><span>Finishing</span></h3>
              <p className='text-justify'>Enhance the visual allure and functionality of your spaces with our precise finishing touches. Whether it's interior enhancements or exterior detailing, our adept artisans utilize premium materials and craftsmanship to elevate the overall aesthetic and usability of your project. Our goal is to make a lasting impression on every aspect of the design.</p>

            </div>
          </div>
          <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
            <div className="service-2 h-100">
             
              <div className="svg">
              <img src={plan} alt="" className=""/>
            </div>
              <h3><span>Architectural Design</span></h3>
              <p className='text-justify'>At the core of our work lies architectural design. Our team of architects seamlessly merges creativity with functionality to craft distinctive structures. Whether it's an innovative office space, an eco-friendly residential community, or a vibrant cultural hub, we specialize in creating architectural wonders that both inspire and fulfill their intended functions.</p>

            </div>
          </div>


          <div className="col-md-6 mb-5 mb-lg-5 col-lg-4">
            <div className="service-2 h-100">
            <div className="svg">
            <img src={bulldozer} alt="" className=""/>
          </div>

              <h3><span>Excavation, Earth Works & Landscaping</span></h3>
              <p className='text-justify'>Specializing in excavation, earthworks, and landscaping services, our team ensures precise execution and expertise in the foundational aspects of your project. From site preparation to terrain sculpting, we meticulously manage each step to create optimal conditions for your construction projects, while simultaneously enhancing the inherent beauty of the surroundings.
 </p>

            </div>
          </div>

        
         

        </div>
      </div>
    </div><section id="services" className="services section-bg">
      <div className="container" data-aos="fade-up">

     

       
      </div>
    </section>
    
    
    
    </div>
  )
}

export default Services