import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

function HomBlog() {

    const [data, setdata] = useState([]);
    useEffect(() => {
        axios.get("https://backend.menberconstructions.com.et/blogfetch")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id).slice(0, 3))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);
   
  return (
    <div>
    
    {data.length>0 &&   <section id="recent-blog-posts" className="recent-blog-posts">
      <div className="container" data-aos="fade-up">

    
    
  <div className=" section-header bg-none">
        <h2>Recent News</h2>
        
      </div>

      <div className="row gy-5">

       {data.map((blog,i)=>{
        return    <div className="col-xl-4 col-md-6" data-aos="fade-up" data-aos-delay="100" key={i}>
          <div className="post-item position-relative h-100">

            <div className="post-img position-relative overflow-hidden">
              <img src={'https://backend.menberconstructions.com.et/images/' + blog.image} class="img-fluid" alt=""/>
              <span className="post-date">{blog.date}</span>
            </div>

            <div className="post-content d-flex flex-column">

            <Link to={`/blogdetail/${blog.id}`} class="text-decoration-none readmore stretched-link"> <h3 className="post-title text-dark">{blog.subject}</h3></Link>

             

              <hr/>

              
                  <Link to={`/blogdetail/${blog.id}`} class="text-decoration-none readmore stretched-link">Read More<i class="bi bi-arrow-right"></i></Link>
            </div>

          </div>
        </div>})}

       
      </div>

      </div>
    </section>}
   
    
    </div>
  )
}

export default HomBlog