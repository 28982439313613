import React, { useEffect, useState } from 'react'
import passport from '../images/passport.jpg'
import { Link } from 'react-router-dom'
import Header from './Header'
import axios from 'axios';

function Blog() {
const [data, setdata] = useState([]);
    useEffect(() => {
        axios.get("https://backend.menberconstructions.com.et/blogfetch")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 6;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
  
    // Function to handle next page click
    const handleNextPage = () => {
      setCurrentPage((prevPage) => prevPage + 1);
    };
  
    // Function to handle previous page click
    const handlePrevPage = () => {
      setCurrentPage((prevPage) => Math.max(prevPage - 1, 1)); // Ensure currentPage doesn't go below 1
    };
  
   
  return (
    <div>
    <Header/>
 <main id="main">

    
    <div class="breadcrumbs d-flex align-items-center lolo" >
      <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

        <h2>News</h2>
        <ol>
          <li><Link to={'/'}>Home</Link></li>
          <li className="text-warning">News</li>
        </ol>

      </div>
    </div>


    { data.length>0 &&  <section id="blog" class="blog">
      <div class="container" data-aos="fade-up" data-aos-delay="100">

        <div class="row gy-4 posts-list">

        {data.slice(startIndex, endIndex).map((blog,i)=>{
          return     <div class="col-xl-4 col-md-6" key={i}>
            <div class="post-item position-relative h-100">

              <div class="post-img position-relative overflow-hidden">
                <img src={'https://backend.menberconstructions.com.et/images/' + blog.image}  class="img-fluid" alt=""/>
                <span class="post-date">{blog.date}</span>
              </div>

              <div class="post-content d-flex flex-column">

              <Link to={`/blogdetail/${blog.id}`} class="text-decoration-none readmore stretched-link"> <h3 className="post-title text-dark">{blog.subject}</h3></Link>

               
                <hr/>

                <Link to={`/blogdetail/${blog.id}`} class="text-decoration-none readmore stretched-link">Read More<i class="bi bi-arrow-right"></i></Link>

              </div>

            </div>
          </div>})}

         

          

        </div>
        <div className="d-flex pagination-wrap justify-content-center mt-5">
          
          
        <div className="custom-pagination d-flex  justify-content-center">
         <Link className="prev" onClick={handlePrevPage} disabled={currentPage === 1}>Prev</Link>
        <span className="d-flex  justify-content-center"> <p>{startIndex +1}</p>-<p>{endIndex}</p></span>
          {data.length>currentPage*5 ? <Link className="next" onClick={handleNextPage} disabled={endIndex >= data.length}>Next</Link>:<Link className="next" >Next</Link>}
        </div>
      
    </div>
       

      </div>
    </section>}

  </main>
    
  
    </div>
  )
}

export default Blog