


import './App.css';
import { Routes,Route,BrowserRouter} from 'react-router-dom';


import Home from './Pages/Home';
import Footer from './Pages/Footer';

import Contact from './Pages/Contact';

import About from './Pages/About';
import Blog from './Pages/Blog';

import BlogDetail from './Pages/BlogDetail';

import { useEffect, useState } from 'react';
import { auth } from './firebase_con';
import NotFoundPage from './Pages/NotFoundPage';
import CompletedProjects from './Pages/CompletedProjects';
import OngoingProjects from './Pages/OngoingProjects';

import Vacancy from './Pages/Vacancy';
import VacancyDetail from './Pages/VacancyDetail';
import Team from './Pages/Team';
import Services from './Pages/Services';



function App() {
const [user, setuser] = useState(null);
  useEffect(() => {
    auth.onAuthStateChanged((authUser)=>{
      if(authUser){
       
      setuser(authUser)

      }})
    }, []);
   console.log(user);
 




  return (
    <BrowserRouter>  

    <Routes>

    <Route path='/' element={<div><Home/><Footer/></div>} />
    <Route path='/contact' element={<div><Contact/><Footer/></div>} />
    <Route path='/vacancy' element={<div><Vacancy/><Footer/></div>} />
    <Route path='/vacancydetail/:id' element={<div><VacancyDetail/><Footer/></div>} />
    <Route path='/blogdetail/:id' element={<div><BlogDetail/><Footer/></div>}/>
  
    <Route path='/about' element={<div><About/><Footer/></div>}/>
    <Route path='/servicedetail' element={<div><Services/><Footer/></div>}/>
    <Route path='/team' element={<div><Team/><Footer/></div>}/>
    <Route path='/completedprojects' element={<div><CompletedProjects/><Footer/></div>}/>
    <Route path='/ongoingprojects' element={<div><OngoingProjects/><Footer/></div>}/>
    <Route path='/blog' element={<div><Blog/><Footer/></div>}/>
   
    <Route path="*"  element={<NotFoundPage />} />

   
  </Routes>

  </BrowserRouter>

  );
}

export default App;
