import React, { useEffect, useState } from 'react'
import Header from './Header'

import about from '../images/about.jpg'

import { Link } from 'react-router-dom'
import Counter from './counter'
import axios from 'axios'


function About() {
  const [data, setdata] = useState({
    happy:0,
    projects:0,
    workers:0

  });


  useEffect(() => {
      axios.get("https://backend.menberconstructions.com.et/numberfetch")
      .then(res=>{
  if (res.data.status==="success") {
      setdata({
        happy:res.data.Result[0].happy,
        projects:res.data.Result[0].projects,
        workers:res.data.Result[0].workers

      })
    
  }
          
      })
      .catch(err=>console.log(err))
     
  }, []);


  return (
    <div>
    <Header/>
    <div class="breadcrumbs d-flex align-items-center lolo" >
    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

      <h2>About</h2>
      <ol>
        <li><Link to={'/'}>Home</Link></li>
        <li className="text-warning">About</li>
      </ol>

    </div>
  </div>




    <section id="features" className="features section-bg">
      <div className="container" data-aos="fade-up">

      <ul className="nav nav-tabs row  g-2 d-flex">

      <li className="nav-item col-3">
        <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#tab-1">
          <h4>History</h4>
        </a>
      </li>
  
      <li className="nav-item col-3">
        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-2">
          <h4>Values</h4>
        </a>
        </li>
      <li className="nav-item col-3">
        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-3">
          <h4>Vision</h4>
        </a>
      </li>
  
      <li className="nav-item col-3">
        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#tab-4">
          <h4>Mission</h4>
        </a>
      </li>
    </ul>
        <div className="tab-content">

          <div class="tab-pane active show" id="tab-1">
          <div className="row">
          <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
            <h3>BACKGROUND HISTORY</h3>
            <p className="fst-italic text-justify">
            First Established in 2012 as a Grade V Building contractor (BC5) and Grade III Water works, 
            under the legal name of Menber construction PLC, registered in Ministry of Construction works 
            Regulatory Authority has now grown to a Grade III Building Contractor (BC3) and Grade III 
            Water Works on the course of planning one of the few trusted contractors that can manage to 
            handle and execute complex projects of higher caliber. In the company’s six-year history, 
            Menber construction continues to show good achievements which can be traced in its truck 
            records. It has grown its capacity in capital and manpower to match the ever-growing demand of 
            the construction industry through understanding clients core values and spirations that is the key 
            to its success. 
            It was established with an objective of 
expanding in the following sectors with excellent performance and providing quality 
product/services in:
            </p>
            <ul>
              <li><i className="bi bi-check2-all"></i>Buildings – Apartments, Condominiums, Hotels, Warehouse & so on</li>
              <li><i className="bi bi-check2-all"></i>Infrastructure - Road, Water, Dams, Bridges, Aviation</li>
              <li><i className="bi bi-check2-all"></i>Bulk Earthworks</li>
              <li><i className="bi bi-check2-all"></i>Concrete Works</li>
            </ul>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 text-center">
            <img src={about} alt="" className="img-fluid"/>
          </div>
        </div>
          </div>

          <div class="tab-pane" id="tab-2">
          <div className="row">
 <div className="col-lg-6 order-1 order-lg-1 text-center">
            <img src={about} alt="" className="img-fluid"/>
          </div>

          <div className="col-lg-6 order-2 order-lg-2 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
          
            <p className="fst-italic text-justify">
            The company strives to be an example in the construction industry by supplying quality product 
            with reasonable cost in timely manner. Menber Construction PLC has made all the necessary 
            preparation to make sure that it achieves its objectives & deliver with excellence to our clients 
            and partners. 
            
            </p>
            <ul>
              <li><i className="bi bi-check2-all"></i><b>QUALITY:</b>we are committed to delivering projects on 
              time, within budget, and exceeding quality standards.</li>
              <li><i className="bi bi-check2-all"></i><b>SAFETY:</b>we prioritize the safety of our employees, 
              subcontractors, and the public.</li>
              <li><i className="bi bi-check2-all"></i><b>CLIENT FOCUS:</b>we build strong relationships with our 
              clients and are dedicated to understanding and exceeding their needs. </li>
              <li><i className="bi bi-check2-all"></i><b>TEAMWORK:</b>: we value collaboration, communication, and mutual respect 
              with our team.</li>
              <li><i className="bi bi-check2-all"></i><b>INNOVATION:</b>we embrace new technologies and methods to continuously 
              improve our processes and services.  </li>
              <li><i className="bi bi-check2-all"></i><b>SUSTAINABILITY:</b>we are committed to environmentally responsible 
              construction practice and minimizing our impact.</li>
            </ul>
          </div>
         
        </div>
            
          </div>

          <div class="tab-pane" id="tab-3">
            <div className="row">
              <div className="col-lg-6 order-2 order-lg-1 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
                <h3>Vision</h3>
                
                <p className="fst-italic text-justify">
                Building a Better Ethiopia: Delivering exceptional, safe and sustainable 
                construction projects across Ethiopia, for living, working, and travel. We 
                achieve this through collaboration, innovation, and a commitment to quality 
                craftsmanship, exceeding our clients’ expectations and improving the quality of life.
                </p>
                
              </div>
              <div className="col-lg-6 order-1 order-lg-2 text-center">
                <img src={about} alt="" className="img-fluid"/>
              </div>
            </div>
          </div>

          <div class="tab-pane" id="tab-4">
          <div className="row">
          <div className="col-lg-6 order-2 order-lg-2 mt-3 mt-lg-0 d-flex flex-column justify-content-center">
       <h3>Mission</h3>
            
            <p className="fst-italic text-justify">
            Building a Better Ethiopia: Delivering exceptional, safe and sustainable 
construction projects across Ethiopia, for living, working, and travel. We 
achieve this through collaboration, innovation, and a commitment to quality 
craftsmanship, exceeding our clients’ expectations and improving the quality of 
life. 
            </p>
          </div>
          <div className="col-lg-6 order-1 order-lg-1 text-center">
            <img src={about} alt="" className="img-fluid"/>
          </div>
        </div>
          </div>

        </div>

      </div>
    </section>



    {data &&  <section id="stats-counter" className="stats-counter section-bg">
      <div className="container">

        <div className="row gy-4">

          <div className="col-lg-3 col-md-6">
            <div className="stats-item d-flex align-items-center w-100 h-100">
              <i className="bi bi-emoji-smile color-blue flex-shrink-0"></i>
              <div>
            <div className='d-flex'><Counter start={1} end={data.happy} duration={1} /><h2>+</h2></div>   
                <p>Happy Clients</p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="stats-item d-flex align-items-center w-100 h-100">
              <i className="bi bi-journal-richtext color-orange flex-shrink-0"></i>
              <div>
              <div className='d-flex'><Counter start={1} end={data.projects} duration={1} /><h2>+</h2></div>   
              
                
                <p>Projects</p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="stats-item d-flex align-items-center w-100 h-100">
              <i className="bi bi-clock color-green flex-shrink-0"></i>
              <div><Counter start={1} end={24} duration={3} />
            
                <p>Hours Of Support</p>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="stats-item d-flex align-items-center w-100 h-100">
              <i className="bi bi-people color-pink flex-shrink-0"></i>
              <div>
              <div className='d-flex'><Counter start={1} end={data.workers} duration={3} /><h2>+</h2></div>   
              
                <p>Hard Workers</p>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section>}
    





    </div>
  )
}

export default About