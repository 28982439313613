import React, { useState } from 'react'

import Header from './Header'
import axios from 'axios'
import { Link } from 'react-router-dom';

function Contact() {
const [data, setdata] = useState({

    name:"",
    email:'',
    phone:'',
    subject:'',
    message:''
});
const [resp, setresp] = useState(null);

const handlecontact=(e)=>{
    e.preventDefault()
   
    axios.post('https://backend.menberconstructions.com.et/contact', {name:data.name,phone:data.phone,email:data.email,subject:data.subject,message:data.message})
      .then(res => {
        if (res.data.status==="success") {
        setresp(res.data.status)
        setdata({name:"",phone:"",email:"",subject:"",message:""})
        }
      })
      .catch(err => console.log(err))


}

  return (
    <div>
  <Header/>
  <div class="breadcrumbs d-flex align-items-center lolo" >
  <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

    <h2>Contact Us</h2>
    <ol>
      <li><Link to={'/'}>Home</Link></li>
      <li className="text-warning">contact</li>
    </ol>

  </div>
</div>

   
    

  <section id="contact" className="contact">
      <div className="container" data-aos="fade-up" data-aos-delay="100">

        <div className="row gy-4">
          <div className="col-lg-6">
            <div className="info-item  d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-map"></i>
              <h3>Our Address</h3>
              <p>Addis Abeba,Bole Sub-city, Zurga Building 4th floor </p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="info-item d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-envelope"></i>
              <h3>Email Us</h3>
              <p>menberconstruction@gmail.com</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6">
            <div className="info-item  d-flex flex-column justify-content-center align-items-center">
              <i className="bi bi-telephone"></i>
              <h3>Call Us</h3>
              <p>0116358080</p>
              <p>0911218044</p>
              <p>0913056327</p>
              <p>0900003351</p>
            </div>
          </div>

        </div>

        <div className="row gy-4 mt-1">

          <div className="col-lg-6 ">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3940.5595249669514!2d38.784888473780434!3d9.01261459104804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b8556fec8f933%3A0x807e913bcfb3c5a2!2zWnVyZ2EgQnVpbGRpbmcgfCBIYXlhaHVsZXQgfCDhi5nhiK3hjIsg4YiF4YqV4Yy7IHwg4YiD4Yur4YiB4YiI4Ym1!5e0!3m2!1sen!2set!4v1718644329160!5m2!1sen!2set" width="100%" height="400" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
   
          </div>

          <div className="col-lg-6">
          <form onSubmit={handlecontact} className="php-email-form">
          { resp && <div className="alert alert-success d-flex justify-content-center" role="alert">
                <strong>Your message has been sent. Thank you!</strong>
            </div>}
          
              <div className="row ">
                <div className="col-lg-6 form-group m-0">
                  <input type="text" name="name" class="form-control" id="name" placeHolder="Your Name"
                  value={data.name} onChange={e => setdata({ ...data, name: e.target.value })}
                   required/>
                </div>
                <div className="col-lg-6 form-group m-0">
                  <input type="email" class="form-control" name="email" id="email" placeHolder="Your Email"
                  value={data.email} onChange={e => setdata({ ...data, email: e.target.value })}
                  required/>
                </div>
              </div>
              <div className="row ">
              <div className="col-lg-6 form-group m-0">
              <input type="number" maxLength={10} value={data.phone} onChange={e => setdata({ ...data, phone: e.target.value })} 
              class="form-control" name="email" id="email" placeHolder="Your Phone number" required/>
            </div>
          
              <div className="form-group col-lg-6 m-0">
                <input type="text" class="form-control" name="subject" id="subject" placeHolder="Subject"
                value={data.subject} onChange={e => setdata({ ...data, subject: e.target.value })}
                required/>
              </div>
              </div>
              <div className="form-group m-0">
                <textarea class="form-control" name="message" rows="3" placeHolder="Message" required
                value={data.message} onChange={e => setdata({ ...data, message: e.target.value })}
                ></textarea>
              </div>
             
              <div class="text-center"> <button className="btn" type="submit" id="sendMessageButton">Send Message</button></div>
            </form>
          </div>

        </div>

      </div>
    </section>

    
    </div>
  )
}

export default Contact
