

import React, { useEffect, useRef, useState } from 'react';
import { Row, Button } from 'react-bootstrap';
import Testimonial from './Let'; 
import axios from 'axios';
import { Link } from 'react-router-dom';

const Test = ({ testimonials }) => {
  const scrollRef = useRef(null);

  const handleScrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollLeft + 300,
        behavior: 'smooth',
      });
    }
  };


 

  const handleScrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: scrollRef.current.scrollLeft - 300,
        behavior: 'smooth',
      });
    }
  };

  




  return (
    <div className="testimonial-carousel-container">
    <div className="section-header">
    <h2>Testimonials</h2>
   
  </div>



      <div className="scroll-buttons">
        

      </div>
      <div className="testimonial-carousel " ref={scrollRef}>
        <Row className="testimonial-row  " onMouseEnter={handleScrollRight}>
          {testimonials.map((testimonial, index) => (
            <Testimonial key={index} {...testimonial} />
          ))}
        </Row>
      </div>
      
      <div className="custom-pagination d-flex justify-content-end mr-3">

      <Link  variant="primary" onClick={handleScrollLeft} className="next"><i class="bi bi-arrow-left"></i></Link>
            <Link  variant="primary" onClick={handleScrollRight} className="prev"><i class="bi bi-arrow-right"></i></Link>
          
           
          </div>
    </div>
  );
};

export default Test;
