import React, { useEffect, useState } from 'react'
  import passport from '../images/passport.jpg'
import { Link } from 'react-router-dom'
import axios from 'axios';
import Header from './Header';
function CompletedProjects() {

  const [hover, sethover] = useState("completed");

  const [data, setdata] = useState([]);


  useEffect(() => {
      axios.get("https://backend.menberconstructions.com.et/portfoliofetch")
      .then(res=>{
  if (res.data.status==="success") {
    console.log(res.data.Result);
      setdata(res.data.Result.sort((a, b) => b.id - a.id))
      
  }
          
      })
      .catch(err=>console.log(err))
     
  }, []);


  

  return (
    <div>
    <Header/>
    <div class="breadcrumbs d-flex align-items-center lolo" >
    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">

      <h1 className='text-white'>Completed projects</h1>
      <ol>
        <li><Link to={'/completedprojects'}>Home</Link></li>
        <li className="text-warning">Completed_projects</li>
      </ol>

    </div>
  </div>




     <section id="projects" className="projects">
      <div className="container" data-aos="fade-up">

        <div className="section-header">
          <h2>Our Projects</h2>
         
        </div>

       

          <div className="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay="200">
          {data.filter((porti) => porti.type === hover).map((ports,i)=>{
            return <div className="col-lg-4 col-md-6 portfolio-item filter-design" key={i}>
                        <div className="portfolio-content h-100">
                        <img src={'https://backend.menberconstructions.com.et/images/' + ports.image} class="img-fluid" alt=""/>
                          <div className="portfolio-info">
                            <h4><i className="bi bi-geo-alt-fill"></i> {ports.location}</h4>
                            <p>{ports.name}</p>
                           
                          </div>
                        </div>
                    
          
                    </div>
          })}
         
          
                  </div>

      

      </div>
    </section>

    
    </div>
  )
}

export default CompletedProjects