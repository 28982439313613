import React, { useEffect, useState } from 'react'
import passport from '../images/about.jpg'
import { Link } from 'react-router-dom'
import axios from 'axios';
import Header from './Header';
function Vacancy() {
  const [data, setdata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
      axios.get("https://backend.menberconstructions.com.et/vacancyfetch")
      .then(res=>{
  if (res.data.status==="success") {
      setdata(res.data.Result.sort((a, b) => b.id - a.id))
      
  }
          
      })
      .catch(err=>console.log(err))
     
  }, []);

  const itemsPerPage = 5;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Function to handle next page click
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  // Function to handle previous page click
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1)); // Ensure currentPage doesn't go below 1
  };


  return (
    <div>

    <Header/>
    <div class="breadcrumbs d-flex align-items-center lolo" >
    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
  
      <h2>Vacancy</h2>
      <ol>
        <li><Link to={'/'}>Home</Link></li>
        <li className="text-warning">Vacancy</li>
      </ol>
  
    </div>
  </div>


   { data.length>0 && <section class="site-section" id="next">
      <div className="container">

        <div className="row mb-5 justify-content-center">
          <div className="col-md-7 text-center">
            <h2 className="section-title mb-2">{data.length} Job Listed</h2>
          </div>
        </div>
        
        <ul className="job-listings mb-5 border-0">
         {data.slice(startIndex, endIndex).map((list,i)=>{
        return  <li className="job-listing d-block d-sm-flex pb-3 pb-sm-0 align-items-center mb-5 border-dark" key={i}>
            <Link to={`/vacancydetail/${list.id}`}></Link>
            <div className="job-listing-logo">
              <img src={passport} alt="" className="img-fluid"/>
            </div>

            <div className="job-listing-about d-sm-flex custom-width w-100 justify-content-between mx-4">
              <div className="job-listing-position custom-width w-50 mb-3 mb-sm-0">
                <h2>{list.subject}</h2>
                <div className="job-listing-meta">
                {list.type&&<span className="badge badge-danger">{list.type}</span>}
              </div>
            
              </div>
              <div className="job-listing-location mb-3 mb-sm-0 custom-width w-25">
                <span className="icon-room"><i className="bi bi-geo-alt-fill"></i>{list.location}</span> 
              </div>
              <div className="job-listing-meta d-flex justify-content-center">
                <button className="btn btn-success py-0">Apply Now</button>
              </div>
            </div>
            
          </li>
         }) }
   
        </ul>

        <div className="d-flex pagination-wrap justify-content-center">
          
          
            <div className="custom-pagination d-flex  justify-content-center">
             <Link className="prev" onClick={handlePrevPage} disabled={currentPage === 1}>Prev</Link>
             <span className="d-flex  justify-content-center"> <p>{startIndex +1}</p>-<p>{endIndex}</p></span>
              {data.length>currentPage*5 ? <Link className="next" onClick={handleNextPage} disabled={endIndex >= data.length}>Next</Link>:<Link className="next" >Next</Link>}
            </div>
          
        </div>

      </div>
    </section>}
    
    
    </div>
  )
}

export default Vacancy