import React from 'react'
import { Link } from 'react-router-dom'
import adetail from '../images/taup.jpg'

function Header() {
  return (
 
<div className="container px-3 oo">

    <nav className="navbar navbar-expand-lg bg-dark navbar-dark px-3">
        <Link to={'/'} className="navbar-brand"><img style={{width:'50px'}} className="rounded-circle " src={adetail} alt=''/></Link>
        <button type="button" className="navbar-toggler border-dark" data-toggle="collapse" data-target="#navbarCollapse">
            <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse justify-content-between " id="navbarCollapse">
            <div className="navbar-nav mr-auto ml-md-5">
                <Link to={'/'} className="nav-item nav-link active">HOME</Link>
                <div className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" data-toggle="dropdown">PROJECTS</Link>
                    <div className="dropdown-menu">
                        
                        <Link to={'/completedprojects'} className="dropdown-item" >Completed projects </Link>
                        <Link to={'/ongoingprojects'} className="dropdown-item"> Ongoing Projects  </Link>
                        
                    </div>
                </div>  <div className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" data-toggle="dropdown">ABOUT</Link>
                <div className="dropdown-menu">
                    
                    <Link to={'/about'} className="dropdown-item" >About Us </Link>
                    <Link to={'/team'} className="dropdown-item"> Team  </Link>
                    
                </div>
            </div>
                <Link to={'/blog'} className="nav-item nav-link">NEWS</Link>
                <Link to={'/servicedetail'} className="nav-item nav-link">SERVICES</Link>
                <Link to={'/vacancy'} className="nav-item nav-link">VACANCY</Link>
               
               
            </div>
            <div className="ml-auto d-flex ">
             <div id="note" className="text-white d-none d-xl-flex pt-3"><small className='h6'>
         
             phone :0116358080  or  0913056327  or 0900003351 or 0911218044</small></div>
           
            </div>


            <div className="ml-auto  ">
       <Link to={'/contact'} className="btn btn-warning jjjk p-2 " >CONTACT US</Link>
           </div>



        </div>
    </nav>
</div>

   
  )
}

export default Header