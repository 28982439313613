import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
const firebaseConfig = {

    apiKey: "AIzaSyCZgQTX7HZ4dVi2DDT0p3mhegObkdEeF7w",
  
    authDomain: "tazima-2e1ff.firebaseapp.com",
  
    projectId: "tazima-2e1ff",
  
    storageBucket: "tazima-2e1ff.appspot.com",
  
    messagingSenderId: "337013911294",
  
    appId: "1:337013911294:web:f89d2b7642d2cdfd1fffaf",
  
    measurementId: "G-6ZJQ014W96"
  
  };
  const app = initializeApp(firebaseConfig);
export const auth=getAuth(app)