import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import about from '../images/about.jpg'
import Header from './Header';
function Team() {

    const [data, setdata] = useState([]);
    useEffect(() => {
        axios.get("https://backend.menberconstructions.com.et/teamfetch")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);


  return (
    <div>
    <Header/>
    <div class="breadcrumbs d-flex align-items-center lolo" >
    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
  
      <h2>Our Team</h2>
      <ol>
        <li><Link to={'/'}>Home</Link></li>
        <li className="text-warning">team</li>
      </ol>
  
    </div>
  </div>

    
      <section className="site-section">
      <div className="container" >
    { data.map((members,i)=>{


      
if (members.id % 2 === 1) {
 return <div className="row align-items-center block__69944 mb-4 shadow" key={i}>

  <div className="col-md-6">
    <img src={'https://backend.menberconstructions.com.et/images/' + members.Image} alt="" className="img-fluid mb-4 rounded"/>
  </div>

  <div className="col-md-6 text-center">
  <h3>{members.Name}</h3>
  <p className="text-muted">{members.Position}</p>
  <p className='text-justify'>{members.Description}</p>
  <div className="social mt-4">
  <div className="social-links d-flex my-3">
  {members.Tweeter && <a href={members.Tweeter} className="d-flex align-items-center justify-content-center"><i className="bi bi-twitter h1 m-2" /></a>}
  {members.Facebook && <a href={members.Facebook} className="d-flex align-items-center justify-content-center"><i className="bi bi-facebook h1 m-2" /></a>}
  {members.Instagram && <a href={members.Instagram} className="d-flex align-items-center justify-content-center"><i className="bi bi-instagram h1 m-2" /></a>}
 { members.Linkedin && <a href={members.Linkedin} className="d-flex align-items-center justify-content-center"><i className="bi bi-linkedin h1 m-2" /></a>}

  </div>
</div>
</div>
</div>
}
else if(members.id % 2 !== 1){
  return  <div className="row align-items-center block__69944 mb-2 shadow" key={i}>

    <div className="col-md-6 order-md-2 ml-md-auto">
    <img src={'https://backend.menberconstructions.com.et/images/' + members.Image}  alt="" className="img-fluid mb-4 rounded"/>
    </div>

    <div className="col-md-6 text-center">
      <h3>{members.Name}</h3>
      <p className="text-muted">{members.Position}r</p>
      <p className='text-justify'>Soluta quasi cum delectus eum facilis recusandae nesciunt molestias accusantium libero dolores repellat id in dolorem laborum ad modi qui at quas dolorum voluptatem voluptatum repudiandae voluptatibus ut? Ex vel  ad explicabo iure ipsa possimus consectetur neque rem molestiae eligendi velit?.</p>
      <div className="social mt-4">
      <div className="social-links d-flex my-3">
      {members.Tweeter && <a href={members.Tweeter} className="d-flex align-items-center justify-content-center"><i className="bi bi-twitter h1 m-2" /></a>}
      {members.Facebook && <a href={members.Facebook} className="d-flex align-items-center justify-content-center"><i className="bi bi-facebook h1 m-2" /></a>}
      {members.Instagram && <a href={members.Instagram} className="d-flex align-items-center justify-content-center"><i className="bi bi-instagram h1 m-2" /></a>}
     { members.Linkedin && <a href={members.Linkedin} className="d-flex align-items-center justify-content-center"><i className="bi bi-linkedin h1 m-2" /></a>}


      </div>
    </div>
</div>


</div>

   } 
      


        })}
        </div>
    </section>










    </div>
  )
}

export default Team