import React, { useEffect, useState } from 'react'

import about from '../images/about.jpg'
import { Link, useParams } from 'react-router-dom'
import axios from 'axios';
import Header from './Header';
function VacancyDetail() {
  const [data, setdata] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    axios.get('https://backend.menberconstructions.com.et/vacancydetail/' + id)
      .then(res => {
        if (res.data.status === "success") {
          setdata(res.data.Result)
         
        }
      })
      .catch(err => console.log(err))
  }, [id]);


  const [appp, setappp] = useState({

    name:"",
    email:'',
    image:'',
  message:''
});
const [resp, setresp] = useState(null);

const handlapplication=(e)=>{
    e.preventDefault()
   console.log(appp.image);

   const formdata = new FormData()
   formdata.append('name', appp.name);
   formdata.append('image', appp.image);
   formdata.append('email', appp.email);
   formdata.append('message', appp.message);
   formdata.append('id', id);
  
   axios.post('https://backend.menberconstructions.com.et/apply',formdata)
      .then(res => {
        if (res.data.status==="success") {
        setresp(res.data.status)
        setappp({name:"",email:"",message:""})
        }
      })
      .catch(err => console.log(err))


}


  return (
    <div>
    <Header/>
    <div class="breadcrumbs d-flex align-items-center lolo" >
    <div className="container position-relative d-flex flex-column align-items-center" data-aos="fade">
  
      <h1 className='text-white'>Vacancy detail</h1>
      <ol>
        <li><Link to={'/'}>Home</Link></li>
        <li><Link to={'/vacancy'}>Vacancy</Link></li>
        <li className="text-warning">Vacancy_detail</li>
      </ol>
  
    </div>
  </div>

        <section className="site-section">
    {data.map((single,i)=>{
    return  <div className="container" key={i}>
      <div className="row align-items-center mb-5">
        <div className="col-lg-8 mb-4 mb-lg-0">
          <div className="d-flex align-items-center">
            <div className="border p-2 d-inline-block mr-3 rounded">
              <img className='uuuu' src={about} alt=""/>
            </div>
            <div>
              <h2>{single.subject}</h2>
              <div>
                <span className="ml-0 mr-2 mb-2"><span class="icon-briefcase mr-2"></span>{single.type}</span>
                <span className="m-2"><span class="icon-room mr-2"></span><i className="bi bi-geo-alt-fill"></i>{single.location}</span>
              
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8">
          <div className="mb-5">
            <figure className="mb-5"><img src={'https://backend.menberconstructions.com.et/images/' + single.image} alt="" class="img-fluid rounded"/></figure>
            <h3 className="h5 d-flex align-items-center mb-4 text-primary"><span class="icon-align-left mr-3"></span>Job Description</h3>
            <p>{single.detail}</p>
            
          </div>
<div className="">
             <div className="apply_job_form white-bg">
                        <h4>Apply Now</h4>
                        <form onSubmit={handlapplication} >
          { resp && <div className="alert alert-success d-flex justify-content-center" role="alert">
                <strong>Your Have Succefully applied.Thank you!</strong>
            </div>}
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input_field border-dark">
                                        <input type="text" className="input_field border-dark" placeHolder="Your name"
                                        value={appp.name} onChange={e => setappp({ ...appp, name: e.target.value })} required/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input_field">
                                        <input type="text" className="input_field border-dark" placeHolder="Email"
                                        value={appp.email} onChange={e => setappp({ ...appp, email: e.target.value })} required/>
                                    </div>
                                </div>
                              
                                <div className="col-md-12">
                                 <div className="input_field">
                                          <input type="file" className="custom-file-input " id="inputGroupFile03" aria-describedby="inputGroupFileAddon03"
                                          onChange={e => setappp({ ...appp, image: e.target.files[0] })} required
                                          />
                                          <label class="custom-file-label px-3" htmlFor="inputGroupFile03">Upload CV</label>
                                        </div>
                                      
                                </div>
                                <div className="col-md-12">
                                    
                                        <textarea className='w-100 px-3'  rows="6"  placeHolder="Coverletter"
                                        value={appp.message} onChange={e => setappp({ ...appp, message: e.target.value })} required
                                        
                                        ></textarea>
                                    </div>
                               
                                <div className="col-md-12 mt-3">
                                    <div className="submit_btn">
                                        <button class="btn btn-success w-100" type="submit">Apply</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div> 
            </div>
</div>
        <div className="col-lg-4">
          <div className="bg-light p-3 border rounded mb-4">
            <h3 className="text-primary  mt-3 h5 pl-3 mb-3 ">Job Summary</h3>
            <ul className="list-unstyled pl-3 mb-0">

              <li className="mb-2"><strong class="text-black">Employment Status:</strong>{single.type}</li>
              <li className="mb-2"><strong class="text-black">Experience:</strong> {single.experience}</li>
              <li className="mb-2"><strong class="text-black">Job Location:</strong>{single.location}</li>
              <li className="mb-2"><strong class="text-black">Salary:</strong>{single.salary}</li>
              <li className="mb-2"><strong class="text-black">Gender:</strong> {single.gender}</li>
              <li className="mb-2"><strong class="text-black">Application Deadline:</strong>{single.deadline}</li>
            </ul>
          </div>

          <div className="bg-light p-3 border rounded">
            <h3 className="text-primary  mt-3 h5 pl-3 mb-3 ">Share</h3>
            <div className="px-3">
              <a href="#" className="pt-3 pb-3 pr-3 pl-0"><span class="bi bi-facebook"></span></a>
              <a href="#" className="pt-3 pb-3 pr-3 pl-0"><span class="bi bi-twitter"></span></a>
              <a href="#" className="pt-3 pb-3 pr-3 pl-0"><span class="bi bi-linkedin"></span></a>
              <a href="#" className="pt-3 pb-3 pr-3 pl-0"><span class="bi bi-pinterest"></span></a>
            </div>
          </div>

        </div>
      </div>
    </div>
    })}
    </section>
    
    
    </div>
  )
}

export default VacancyDetail