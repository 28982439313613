import React, { useState, useEffect } from 'react';

const Counter = ({ start, end, duration }) => {
  const [counter, setCounter] = useState(start);

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter < end) {
        setCounter(prevCounter => prevCounter + 1);
      } else {
        clearInterval(interval);
      }
    }, duration * 1000 / (end - start));

    return () => clearInterval(interval);
  }, [counter, end, duration]);

  return <span>{counter}</span>;
};

export default Counter;
